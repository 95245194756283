<template>
	<div class="view pa24" >
		<el-date-picker :picker-options="pickerOptions" v-model="search.date" type="daterange" range-separator="至" start-placeholder="开始日期"
			end-placeholder="结束日期">
		</el-date-picker>
		<el-button class="ml10" type="primary" @click="upsearch">查询</el-button>
		<commonTable :paginationVisible="false" :tableData="tableData" :loading="loading" >
			<template v-slot:table>
				<el-table-column align="center" label="总代收金额/次数">
					<template slot-scope="scope">
						<div style="color: #51CDCB;">{{scope.row.daishou_totalmoney}}</div>
						<div style="color: #51CDCB;">{{scope.row.daishou_totalnum}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="代收成功金额/次数">
					<template slot-scope="scope">
						<div style="color: #65ca00;">{{scope.row.daishou_success_totalmoney}}</div>
						<div style="color: #65ca00;">{{scope.row.daishou_success_totalnum}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="代收失败金额/次数">
					<template slot-scope="scope">
						<div style="color: #ff0000;">{{scope.row.daishou_fail_totalmoney}}</div>
						<div style="color: #ff0000;">{{scope.row.daishou_fail_totalnum}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="到账金额" prop="daishou_shidao"></el-table-column>
				<el-table-column align="center" label="代收费用" prop="daishou_fee"></el-table-column>
				<el-table-column align="center" label="提款金额/次数">
					<template slot-scope="scope">
						<div style="color: #ffaa00;">{{scope.row.daishou_tikuan_money}}</div>
						<div style="color: #ffaa00;">{{scope.row.daishou_tikuan_num}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="提款手续费" prop="daishou_tikuan_sxf"></el-table-column>
				<el-table-column align="center" label="代付成功金额/次数">
					<template slot-scope="scope">
						<div style="color: #51CDCB;">{{scope.row.daifu_success_totalmoney}}</div>
						<div style="color: #51CDCB;">{{scope.row.daifu_success_totalnum}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="代付失败金额/次数">
					<template slot-scope="scope">
						 <div style="color: #ff0000;">{{scope.row.daifu_fail_totalmoney}}</div>
						 <div style="color: #ff0000;">{{scope.row.daifu_fail_totalnum}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="代付入金金额/次数">
					<template slot-scope="scope">
						<div style="color: #ff0000;">{{scope.row.daifu_recharge_totalmoney}}</div>
						<div style="color: #ff0000;">{{scope.row.daifu_recharge_totalnum}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="代付费用" prop="daifu_fee"></el-table-column>
			</template>
		</commonTable>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import {
		get_deal_static,
	} from "@/api/settlement";
	import Moment from 'moment';
	export default {
		name: 'windowSet',
		data() {
			return {
        pickerOptions: {
          shortcuts: [{
            text: '今日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, start]);
            }
          },{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
				search:{
					date:''
				},
				tableData: [],
				loading: false, //表格加载
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			this.queryPage();
		},

		methods: {
			async queryPage(payload) {
				let data = {...payload};
				try {
					this.loading = true;
					const result = await get_deal_static(data);
					this.loading = false;
					this.tableData = [result.data];
				} catch (error) {
					this.tableData = []
					this.loading = false;
				}
			},
			upsearch(){
				let data = {};
				if(this.search.date) {
					data.start_time = Moment(this.search.date[0]).format('YYYY-MM-DD 00:00:00');
					data.end_time = Moment(this.search.date[1]).format('YYYY-MM-DD 23:59:59');
				}
				this.queryPage(data);
			}
		},
	};
</script>

<style lang="scss" scoped>
</style>